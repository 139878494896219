<template>
    <div id="applicant-business-data" class="mt-base">
        <div v-if="currentStep == 'pm_5_personal_profile_sic_check'" class="vx-row">
            <div class="vx-col w-full">
                <h2>Cuéntanos sobre tu empresa</h2>
                <p>Necesitamos conocer la estructura de tu empresa</p>
                <vs-divider></vs-divider>
            </div>
            
            <!-- ¿CUANTOS ACCIONISTAS TIENE LA EMPRESA? -->
            <div v-if="!hasSelected" class="vx-col sm:w-full md:w-3/5">
                <div>
                    <h5 class="bold black mb-2 mt-4">¿El representante legal es accionista?</h5>
                    <!-- <p class="text-md">Selecciona la cantidad de accionistas incluyendo al representante legal</p> -->
                    <div class="vx-row mb-4">
                        <div class="vx-col sm:w-1/2 lg:w-1/6 mb-8 mt-2" v-for="(item, index) in replegal_options"
                            :key="index">
                            <rg-radio class="w-full" groupName="replegalStakeholder" :label="item.label" :value="item.value"
                                v-model="replegal_value" />
                        </div>
    
                    </div> 
                </div>
                <div>
                    <h5 class="bold black mb-2 mt-4">¿Cuántos accionistas tiene la empresa?</h5>
                    <p class="text-md">Selecciona la cantidad de accionistas.</p>
                    <div class="vx-row mb-base">
                        <div class="vx-col sm:w-1/2 lg:w-1/6 mb-8 mt-2" v-for="(item, index) in stakeholders_options"
                            :key="index">
                            <rg-radio class="w-full" groupName="needsInitialPayment" :label="item.label" :value="item.value"
                                v-model="stakeholdersValue" />
                        </div>
    
                    </div>
                </div>
                <vs-button color="black" @click.stop="selectStakeholders()">Continuar</vs-button>
            </div>

            <!-- CUANDO YA SE DEFINIO CUANTOS ACCIONISTAS SON -->
            <div v-else class="vx-col w-full">
                <h5 class="bold black mb-2">Completa la información del cuadro accionario</h5>
                <p class="text-md mb-base">Asegúrate de tener la información completa de los accionistas y representante legal.</p>

                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-7/12 mb-4">
                        <vs-collapse id="stakeholder-form" v-if="hasSelected">
                            <p v-if="stakeholdersValue >= 4" class="mb-4 bold">
                                Si tienes más de 4 accionistas, completa la información del accionista con mayor acciones y/o pertenezca al consejo de administración.
                            </p>
                            <vs-collapse-item v-for="(item, index) in stakeholders" :class="!item.isValid ? 'danger' : ''">
                                <div slot="header" class="bold">
                                    {{ item.role_name }}
                                </div>
                                <stakeholder-form 
                                    ref="stakeholderForm" 
                                    :stakeholder="item" 
                                    :repLegalIsMainUser="rep_legal_is_main_user" 
                                    :hasMoreThanFourStakeholders="hasMoreThanFourStakeholders"
                                    :repLegalIsStakeholder="replegal_value"
                                    @change="onChangeRepLegal"
                                    ></stakeholder-form>
                            </vs-collapse-item>
                        </vs-collapse>
                    </div>
                    <div class="vx-col sm:w-full md:w-7/12 mb-base">
                        <p class="bold mb-4">Porcentaje de acciones: {{ currentSharedValue }}%</p>
                        <div class="flex mb-4">
                            <vs-checkbox v-model="check_legal"
                                name="legal" v-validate="requiredRules"
                                :danger="hasError('legal')"
                                :danger-text="errorText('legal')"
                                :success="isSuccess('legal')">
                            
                            </vs-checkbox>
                            <p>
                                Confirmo que los datos registrados son verdaderos
                                y coinciden con la información actual de la 
                                empresa (Asamblea, Acta Constitutiva, etc.)
                            </p>
                        </div>
                    </div>
                    <div class="vx-col sm:w-full md:w-7/12">
                        <div class="flex">
                            <vs-button color="black" type="border" class="mr-4" @click.stop="resetForms()">Atrás</vs-button>
                            <vs-button color="black" @click.stop="saveBusinessData()">Continuar</vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="vx-col w-full">
                <h2>Sobre el obligado solidario</h2>
                <p>Información sobre el rol de obligado solidario</p>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <div class="vx-row m-2 mt-base">
                    <div class="vx-col sm:w-full md:w-2/3 address-card p-12">
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-2/12">
                                <img :src="osIcon" svgClasses="w-10 h-10 m-1 mr-2 mt-0"
                                class="cursor-pointer text-accent align-self-felx-start" />
                            </div>
                            <div class="vx-col sm:w-full md:w-2/3">
                                <!-- <h2 class="mb-2">Serás el obligado solidario</h2> -->
                                <!-- <ul class="mb-4">
                                    <li><span class="bold">{{ guarantee_profile.name }}</span></li>
                                    <li>{{ guarantee_profile.rfc }}</li>
                                    <li v-if="!guarantee_is_moral">{{ guarantee_profile.email }}</li>
                                    <li v-if="!guarantee_is_moral">{{ guarantee_profile.phone }}</li>
                                </ul> -->
                                <!-- <p class="mb-base">Se le solicitará firmar el contrato y pagaré. Fungirá como obligado solidario y responderá por el pago total del crédito a RedGirasol. </p> -->
                                <p class="mb-base">Gracias por compartirnos tu información. Los accionistas que serán integrados como obligados solidarios recibirán un correo con instrucciones para continuar su proceso pronto. </p>
                                <vs-button @click.stop="updateStep()" color="black">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StakeholderForm from '../shared_components/StakeholderForm.vue';
import stpHelper from "@mixins/stpHelper";


const LOAN_TYPE_ECOTECNOLOGIAS = 1;
const LOAN_TYPE_PERSONAL = 5;
const LOAN_TYPE_PYME = 4;

const stakeholders_options = [
    { label: "1", value: "1", desc: null },
    { label: "2", value: "2", desc: null },
    { label: "3", value: "3", desc: null },
    { label: "4 o más", value: "4", desc: null },
    // { label: "más de 4", value: "5", desc: null },
];

const replegal_options = [
    { label: "Si", value: "1", desc: null },
    { label: "No", value: "2", desc: null }
];


export default {
    name: "ApplicantBusinessData",
    props: ["onboardingStepData", "isMoral"],
    mixins: [stpHelper],
    components: {
        StakeholderForm
    },
    data: () => ({
        isEdit: false,
        errorMssg: null,
        sameAddress: "1",
        requiredRules: "required",
        requiredPlaceholder: "(Requerido)",
        rfc: null,
        curp: null,
        isMounted: false,
        stakeholders_options: stakeholders_options,
        stakeholdersValue: null,
        replegal_options: replegal_options,
        replegal_value: null,
        hasSelected: false,
        check_legal: false,
        stakeholders: [],
        osIcon: require("@assets/images/loan-onboarding/os_icon.svg"),
    }),
    computed: {
        currentStep() {
            return this.base.loan_request_step;
        },
        base() {
            return this.onboardingStepData;
        },
        finance() {
            return this.base.finance;
        },
        business() {
            return this.onboardingStepData.client.user.business;
        },
        business_personal() {
            return this.onboardingStepData.client.user.business.personal;
        },
        rep_legal_is_main_user() {
            return true;
        },
        currentStakeholders() {
            return this.onboardingStepData.client.user.business.stakeholders != null ? this.onboardingStepData.client.user.business.stakeholders : [];
        },
        hasStakeHolders() {
            return this.currentStakeholders != null ? this.currentStakeholders.length > 0 : false;
        },
        hasMoreThanFourStakeholders() {
            return this.stakeholdersValue > 4;
        },
        currentSharedValue() {
            let shared = 0;

            this.stakeholders.forEach((stakeholder) => {
                shared += stakeholder.share_percentage
            });

            return shared;
        },
        guarantee() {
            return this.onboardingStepData.finance.guarantee != null ? this.onboardingStepData.finance.guarantee.guarantee_profile : null; 
        },
        guarantee_is_moral() {
            if(this.guarantee != null) {
                return this.guarantee.business != null;
            } 

            return false;
        },
        guarantee_profile() {
            if(this.guarantee_is_moral) {
                const data = this.guarantee.business;
                return {
                    name: data.name,
                    full_address: data.address.full_address,
                    rfc: data.rfc,
                };
            } else {
                const data = this.guarantee.personal;
                return {
                    name: data.first_name + " " + (data.second_name||'') + " " + data.last_name_1 + " " + (data.last_name_2||''),
                    full_address: data.address.full_address,
                    phone: data.phone.phone,
                    rfc: data.rfc
                };
            }
        },
    },
    beforeMount() {
        this.isMounted = true;
    },
    async mounted() {
        await this.getStakeholders();
        if (this.hasStakeHolders) {
            this.setStakeholders();
            this.stakeholdersValue = this.currentStakeholders.length + 1;
        }
    },
    methods: {
        setStakeholders() {
            let step = 1;
            // Sí ya tiene stakeholders
            if(this.hasStakeHolders) {
                this.currentStakeholders.forEach((stakeholder, index) => {
                    let personType = stakeholder.stakeholder_pp_id != null ? "1" : "2";
                    this.stakeholders.push({
                        role_name: "Accionista " + (index + 2),
                        role: "stakeholder_" + (index + 2),
                        profile: personType == "1" ? stakeholder.personal_profile : stakeholder.business_profile,
                        share_percentage: stakeholder.share_percentage * 10,
                        email: stakeholder.email,
                        person_type: personType,
                        isValid: true,
                        is_max_percentage: false,
                        id: stakeholder.id
                    });
                });
            }

            let currentStakeholdersNum = this.replegal_value == "1" ? this.currentStakeholders.length + 1 : this.currentStakeholders.length;

            if(this.stakeholdersValue <= 4) { // Sí es mayor a 4 entonces no se suman más.
                let newStakeholdersValue = this.stakeholdersValue - currentStakeholdersNum;
                let numToSum = 0;
                if(this.replegal_value == "1") {
                    numToSum = this.hasStakeHolders ? 2 : 1;
                } else {
                    numToSum = this.hasStakeHolders ? 1 : 0;
                }
                if(newStakeholdersValue > 0) {
                    for (let i = 0; i < newStakeholdersValue; i++) {
                        this.stakeholders.push({
                            role_name: "Accionista " + (i + step + numToSum),
                            role: "stakeholder_" + (i + step + numToSum),
                            profile: {
                                first_name: null,
                                second_name: null,
                                last_name_1: null,
                                last_name_2: null,
                                curp: null,
                                rfc: null,
                                birth_date: null,
                                citizenship: 700,
                                phone: {
                                    phone: null,
                                    country_calling_code: "+52",
                                },
                                phone_id: null,
                            },
                            share_percentage: null,
                            role_type: null,
                            email: null,
                            person_type: null,
                            isValid: true,
                            is_max_percentage: null,
                            id: null
        
                        });
                    }
                }
            } else {
                if(!this.hasStakeHolders) {
                    this.stakeholders.push({
                            role_name: "Accionista 2",
                            role: "stakeholder_2",
                            profile: {
                                first_name: null,
                                second_name: null,
                                last_name_1: null,
                                last_name_2: null,
                                curp: null,
                                rfc: null,
                                birth_date: null,
                                citizenship: 700,
                                phone: {
                                    phone: null,
                                    country_calling_code: "+52",
                                },
                                phone_id: null,
                            },
                            share_percentage: null,
                            role_type: null,
                            email: null,
                            person_type: null,
                            isValid: true,
                            is_max_percentage: null,
                            id: null
        
                        });
                }
            }
        },
        async selectStakeholders() {

            if(this.replegal_value == null) {
                this.missingFieldsNotif(null, "Selecciona si el representante legal es accionista.");
                return;

            }

            if (this.stakeholdersValue) {
                if(this.replegal_value == "1") {
                    this.stakeholders = [
                    {
                        role_name: "Representante legal",
                        role: "legal_rep",
                        profile: this.business_personal,
                        email: this.base.client.user.email,
                        share_percentage: null,
                        isValid: true,
                        is_max_percentage: true,
                        personal_profile_id: this.business_personal.id,
                        id: null
                    }];
                }

                this.setStakeholders();

                this.hasSelected = true;
            } else {
                this.showLoading(false);
                this.missingFieldsNotif(null, "Selecciona primero cuantos accionistas tiene la empresa.");
            }
        },
        backStakeHolders() {
            this.hasSelected = false;
        },
        async getStakeholders() {
            this.showLoading(true);
            const res = await axios.get(`/api/loan-onboarding/get/${this.business.id}/get-stakeholders`);
            if (res.status == 200) {
                this.onboardingStepData.client.user.business.stakeholders = res.data;
            } else {
                this.failedOperationNotif(null, "No se pudo obtener la información de los accionistas.");
            }
            this.showLoading(false);
        },
        async saveBusinessData() {
            const flags = [];
            let flag = false;

            const forms = this.$refs.stakeholderForm;

            for (let index = 0; index < forms.length; index++) {
                const element = forms[index];
                flag = await element.saveDataForm();
                flags.push(flag);
            }

            if (flags.includes(false)) {
                this.missingFieldsNotif();
                return;
            }

            if(this.currentSharedValue > 100) {
                this.missingFieldsNotif(null, "El porcentaje de acciones no puede superar el 100%");
                return;
            }

            if(!this.check_legal) {
                this.missingFieldsNotif(null, "Debes confirmar que los datos registrados son verdaderos y coinciden con la información actual de la empresa (Asamblea, Acta Constitutiva, etc.)");
                return;
            }

            this.saveStakeholdersData();
        },
        async saveStakeholdersData() {
            try {
                this.showLoading(true, "Guardando estructura empresarial...");
                const res = await axios.post(`/api/loan-onboarding/post/stakeholders/${this.base.id}/save-stakeholders`, {
                    stakeholders: this.stakeholders
                });
                if(res.status == 200) {
                    this.showLoading(false);
                    await this.$emit("updated", 1);
                }
                this.showLoading(false);
            } catch (error) {
                console.error(error);
            }
        },
        async updateStep() {
            this.showLoading(true);
            await axios.put(`/api/loan-onboarding/put/${this.base.id}/update-os-loan`);
            await this.$emit("updated", 1);
            this.createStpAccount(this.base.client.id);
            this.showLoading(false);
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        onChangeRepLegal(e) {
            console.log(e);

            if(e) { // Sí es true, se debe eliminar un stakeholder porque el replegal es accionista
                if(this.stakeholders.length > 1) { //Sólo es el rep legal, no se debería eliminar
                    this.stakeholders.pop();
                }
            } else { // Sino,  se debe agregar un stakeholder porque el replegal es accionista
                let stakeholderNum = this.stakeholdersValue == 1 ? 0 : 1;
                this.stakeholders.push({
                            role_name: `Accionista ${this.stakeholders.length + stakeholderNum}`,
                            role: `stakeholder_${this.stakeholders.length + stakeholderNum}`,
                            profile: {
                                first_name: null,
                                second_name: null,
                                last_name_1: null,
                                last_name_2: null,
                                curp: null,
                                rfc: null,
                                birth_date: null,
                                citizenship: 700,
                                phone: {
                                    phone: null,
                                    country_calling_code: "+52",
                                },
                                phone_id: null,
                            },
                            share_percentage: null,
                            role_type: null,
                            email: null,
                            person_type: null,
                            isValid: true,
                            is_max_percentage: null,
                            id: null
                        });
            }
        },
        resetForms() {
            this.stakeholders = [];
            this.stakeholdersValue = null;
            this.replegal_value = null;
            this.hasSelected = false;
            this.check_legal = false;
        }
    }
}
</script>

<style>
.vs-collapse-item {
    border: 1px solid #28de18 !important;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.vs-collapse-item.danger {
    border: 1px solid rgba(var(--vs-danger),1) !important;
}
#stakeholder-form .vs-collapse-item.open-item .vs-collapse-item--content {
    max-height: fit-content !important;
}
</style>