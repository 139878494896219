<template>
    <div id="loan-step-page">
        <template v-if="isMoral">
            <ApplicantLoanDetailPM :id="id" />
        </template>
        <template v-else>
            <ApplicantLoanDetail :id="id" />
        </template>
    </div>
</template>

<script>
import ApplicantLoanDetail from './onboarding-step-pages/ApplicantLoanDetail.vue';
import ApplicantLoanDetailPM from './onboarding-step-pages/ApplicantLoanDetailPM.vue';
    export default {
        name: "LoanOnboardingStepPage",
        props: ['id'],
        data: () => ({

        }),
        components: {
            ApplicantLoanDetail,
            ApplicantLoanDetailPM
        },
        computed: {
            isMoral() {
                return this.UserPersonType == 0;
            }
        }
    }
</script>