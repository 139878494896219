<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">
                    {{ getTitle() }}
                </h3>
                <p>Esta información debe coincidir con el comprobante que nos proporcionarás.</p>
                <vs-divider></vs-divider>
                
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row">
                        <div v-for="section in allSections" :key="section.id" :class="colClass">
                            <div class="vx-row">
                                <template v-for="f in section.fields">
                                    <!-- Campos automaticos -->
                                    <form-field 
                                        :ref="`section_id_${section.id}`" 
                                        v-if="isAutoFormField(f) && !showFields(f.db_field)" 
                                        :key="f.id"
                                        :f="f" 
                                        :dataContainer="getContainerForField(section, f)"
                                        :collectionsRoot="collections" 
                                        :onChangeReceptor="onFieldChange"
                                        :hasBackofficeAccess="false" 
                                        :blockedByDocument="f.blockedByDocument"
                                        :evaluator="evaluateCondition" 
                                        :inputClassLarge="true"
                                        :countriesList="collections.countries" 
                                        :editableByBackoffice="true" />
                                </template>
                            </div>
                        </div>

                        <vs-divider></vs-divider>
                        <div v-for="section in allSections" :key="section.id" :class="colClass">
                            <div class="vx-row">
                                <template v-for="f in section.fields">
                                    <!-- Campos automaticos -->
                                    <form-field 
                                        :ref="`section_id_${section.id}`" 
                                        v-if="isAutoFormField(f) && showFields(f.db_field)" 
                                        :key="f.id"
                                        :f="f" 
                                        :dataContainer="getContainerForField(section, f)"
                                        :collectionsRoot="collections" 
                                        :onChangeReceptor="onFieldChange"
                                        :hasBackofficeAccess="false" 
                                        :blockedByDocument="f.blockedByDocument"
                                        :evaluator="evaluateCondition" 
                                        :inputClassLarge="true"
                                        :countriesList="collections.countries" 
                                        :editableByBackoffice="true" />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSections(`section_id_${current_section}`)">{{ isEdit ? 'Guardar' : 'Continuar' }}</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const sectionsToContainers = [
    {
        id: 16, containers: [{ db: 'addresses', path: 'client.user.business.address' }]
    },
    {
        id: 32, containers: [
            { db: 'addresses', path: 'client.user.personal.address' },
            { db: 'kyc_profiles', path: 'client.kyc_profile' }
        ]
    },
];


export default {
    name: "ApplicantAddress",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: {
        project: Object,
        isEdit: Boolean,
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col w-full",
        pmSections: [16],
        pfSections: [32],
        allSections: [],
        formSections: [],

        allSections: [],
        formSections: [],

        documentsFields: [],
        documentsStates: [],

        validatedDocuments: [],
        allDocumentFields: [],
        mexicoCountryId: 700,
        collections: {
            cities: [],
            states: [],
            countries: [],
            foreign_countries: [],
            family_groups: [],
            tools: [],
            customer_types: [],
            banks: [],
            fiscal_states: [],
            fiscal_cities: [],
            fiscal_neighborhoods: [],
            neighborhoods: [],

            states_application_address: [],
            cities_application_address: [],
            neighborhoods_application_address: [],
            states_application_business_address: [],
            cities_application_business_address: [],
            neighborhoods_application_business_address: [],
        },
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        await this.getCollections();
        await this.getSections();
        this.isMounted = true;
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        current_section() {
            return this.isMoral ? this.pmSections[0] : this.pfSections[0];
        },
        personal() {
            return this.base.client.user.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        is_applicant_platform() {
            return true;
        },
        kycProfile() {
            return this.base.client.kyc_profile;
        },
        is_sfv_loan() {
            return this.base.finance.loan_type == 1 && this.base.finance.financial_product_id == 1;
        },
        address() {
            if(this.isMoral) {
                return this.business.address;
            } else {
                return this.personal.address;
            }
        }
    },
    methods: {
        async getSections() {
            this.showLoading(true);
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                s.containers = this.getContainersForSection(s.id);
                s.documentFields = [];

                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this);
                });
            });
            this.showLoading(false);
        },
        getContainersForSection(section_id) {
            let c = sectionsToContainers.find(f => f.id == section_id);
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.countries = colls.countriesList;
                this.collections.foreign_countries = colls.foreignCountriesList;
                this.collections.states = colls.statesList;

            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        // GUARDAR
        async saveSections(gslug) {
            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.missingFieldsNotif();
                }
            } else {
                this.showLoading(true, "Guardando información...");
                //guardar solo la informacion que se ha modificado
                this.basePayload = this.collectFormData(gslug);
                // this.basePayload.project_id = this.projectId;
                if (this.objectIsEmpty(this.basePayload)) {
                    this.saveSuccessNotif();
                    await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                    await this.$emit("updated", 1);
                    this.showLoading(false);
                    return;
                }

                this.basePayload.id = this.base.id;
                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(this.basePayload);
                    await axios.put(`/api/v2/projects/${this.projectId}/deepUpdate`, this.basePayload);
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                    await this.$emit("updated", 1);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.showLoading(false);
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }
            }
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;

                    obj = { id: cc.container.id };
                    fields.forEach(f => {
                        obj[f.fname] = cc.container[f.fname];
                    });

                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            f.setError(sp.error);
                            this.errorNotif(n, sp.error, 10000);
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        verify_zip_code_application_address() {
            this.verifyZipCode(this.personal.address, {
                zipCodeVariable: 'zip_code_application_address',
                statesArray: 'states_application_address',
                citiesArray: 'cities_application_address',
                neighborhoodsArray: 'neighborhoods_application_address'
            });
        },
        set_zip_code_values_application_address() {
            this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
                zipCodeVariable: 'zip_code_application_address',
                statesArray: 'states_application_address',
                citiesArray: 'cities_application_address',
                neighborhoodsArray: 'neighborhoods_application_address'
            });
        },

        verify_zip_code_application_business_address() {
            this.verifyZipCode(this.business.address, {
                zipCodeVariable: 'zip_code_application_business_address',
                statesArray: 'states_application_business_address',
                citiesArray: 'cities_application_business_address',
                neighborhoodsArray: 'neighborhoods_application_business_address'
            });
        },
        set_zip_code_values_application_business_address() {
            this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
                zipCodeVariable: 'zip_code_application_business_address',
                statesArray: 'states_application_business_address',
                citiesArray: 'cities_application_business_address',
                neighborhoodsArray: 'neighborhoods_application_business_address'
            });
        },
        getTitle() {
            return this.isEdit ? "Edita tu domicilio." : "Información del domicilio donde vives."
        },
        set_residence_ownership() {
            if (this.kycProfile !== null) {
                const residenceOwnership = {
                    "own": "Propio",
                    "rented": "Rentada",
                    "family": "De un familiar",
                    "shared": "Compartida",
                    "irregular": "Otro",
                };
                this.kycProfile.residence_ownership = (residenceOwnership[this.kycProfile.residence_ownership] || null);
            }

            return null;
        },
        showFields(dbField) {
            if(
                dbField == 'years_living_in_same_place'
                || dbField == 'residence_ownership'
            ) {
                return true;
            }

            return false;
        },
        location_address_is_same_as_client_address() {
            // Se tiene que consultar primero la fg_location
            const fgLocation = this.base.project_fg_locations[0];

            // Sí existe, entonces se compara la dirección, si no mandamos null
            if(fgLocation) {
                this.address.location_address_same_as_user_address = this.address.id == fgLocation.location.address_id ? 1 : 2;
            } else {
                this.address.location_address_same_as_user_address = 2;
            }
        },
    }
}
</script>