<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">Ingresos y gastos mensuales.</h3>
                <p>Favor completa la siguiente información.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-10/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <h4 class="mb-4">Ingresos</h4>
                        </div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Ingresos mensuales comprobables"
                                name="incomeSourceProofOne"
                                v-money="money"
                                v-validate="requiredRules"
                                v-model.lazy="payload.incomeSourceProofOne"
                                :danger="hasError('incomeSourceProofOne')"
                                :danger-text="errorText('incomeSourceProofOne')"
                                :success="isSuccess('incomeSourceProofOne')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                            <small><i>Ingresos comprobables por medio de recibos de nómina o estados de cuenta bancarios,  después de impuestos.</i></small>
                        </div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Otros ingresos"
                                name="incomeSourceProofTwo"
                                v-money="money"
                                v-model.lazy="payload.incomeSourceProofTwo"
                                :danger="hasError('incomeSourceProofTwo')"
                                :danger-text="errorText('incomeSourceProofTwo')"
                                :success="isSuccess('incomeSourceProofTwo')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <div class="vx-col w-full">
                            <h4 class="mb-4">Gastos</h4>
                        </div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Alimentos, salud y ropa"
                                name="outcomeOne"
                                v-money="money"
                                v-validate="requiredRules"
                                v-model.lazy="payload.outcomeOne"
                                :danger="hasError('outcomeOne')"
                                :danger-text="errorText('outcomeOne')"
                                :success="isSuccess('outcomeOne')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Transporte y gasolina"
                                name="outcomeTwo"
                                v-money="money"
                                v-validate="requiredRules"
                                v-model.lazy="payload.outcomeTwo"
                                :danger="hasError('outcomeTwo')"
                                :danger-text="errorText('outcomeTwo')"
                                :success="isSuccess('outcomeTwo')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <div class="w-full"></div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Entretenimiento"
                                name="outcomeThree"
                                v-money="money"
                                v-validate="requiredRules"
                                v-model.lazy="payload.outcomeThree"
                                :danger="hasError('outcomeThree')"
                                :danger-text="errorText('outcomeThree')"
                                :success="isSuccess('outcomeThree')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                        <div :class="colClass">
                            <vs-input
                                class="w-full"
                                label="Seguros"
                                name="outcomeFour"
                                v-money="money"
                                v-validate="requiredRules"
                                v-model.lazy="payload.outcomeFour"
                                :danger="hasError('outcomeFour')"
                                :danger-text="errorText('outcomeFour')"
                                :success="isSuccess('outcomeFour')"
                                icon-pack="feather"
                                icon="icon-dollar-sign"
                                :placeholder="requiredPlaceholder"
                            />
                        </div>
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSection()">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import {VMoney} from 'v-money';
import currencyHelper from '@mixins/currencyHelper';

export default {
    name: "PersonalIncomeSourceInformation",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper, currencyHelper],
    directives: {money: VMoney},
    components: {
        FormField
    },
    props: {
        project: Object
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col sm:w-full md:w-3/12 mb-5",
        requiredRules: "required",
        errorMssg: null,
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        payload: {
            incomeSourceProofOne: null,
            incomeSourceProofTwo: null,
            outcomeOne: null,
            outcomeTwo: null,
            outcomeThree: null,
            outcomeFour: null,
        },
        payloadUnmoney: {
            incomeSourceProofOne: null,
            incomeSourceProofTwo: null,
            outcomeOne: null,
            outcomeTwo: null,
            outcomeThree: null,
            outcomeFour: null,
        },
        money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false
        },
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        this.setData();
        this.isMounted = true;
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        personal() {
            return this.base.client.user.personal;
        },
        personal_credit() {
            return this.personal.credit_profile;
        },
        business() {
            return this.base.client.user.business;
        },
        incomeSources() {
            return this.personal.p_p_income_sources[0];
        },
        incomeSourcesProofOne() {
            return this.personal.p_p_income_sources[0].income_sources_proofs[0];
        },
        incomeSourcesProofTwo() {
            return this.personal.p_p_income_sources[0].income_sources_proofs[1];
        },
    },
    methods: {
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        onSelect({ dialCode }) {
            this.payload.phoneReq.country_calling_code = `+${dialCode}`;
        },
        setData() {
            if(this.incomeSources) {
                this.payloadUnmoney.incomeSourceId = this.incomeSources.id;
            }
            
            if(this.personal_credit.monthly_incomes != null) {
                const incomes = JSON.parse(this.personal_credit.monthly_incomes);
                this.payload.incomeSourceProofOne = incomes.main;
                this.payload.incomeSourceProofTwo = incomes.other;
            }
            

            if(this.personal_credit.monthly_expenses != null) {
                // información de gastos
                const expenses = JSON.parse(this.personal_credit.monthly_expenses);
                // console.log(expenses);
                this.payload.outcomeOne = expenses.food;
                this.payload.outcomeTwo = expenses.transportation;
                this.payload.outcomeThree = expenses.services;
                this.payload.outcomeFour = expenses.ensurance;
            }
        },
        async saveSection() {
            // validar inputs instanciados en la vista
            let res2 = await this.validateMoneyValues();
            // console.log(this.payloadUnmoney);
            if (!res2) {
                this.missingFieldsNotif();
                return false;
            }

            try {
                this.showLoading(true);
                const res = await axios.post(`/api/loan-onboarding/post/${this.ApplicantId}/update-proofs`, this.payloadUnmoney);
                this.saveSuccessNotif();
                this.showLoading(false);
                await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                await this.$emit("updated", 1);
            } catch (error) {
                console.log(error);
                this.showLoading(false);
            }
        },
        validateMoneyValues() {
            this.payloadUnmoney.incomeSourceProofOne = this.unMoneyFormat(this.payload.incomeSourceProofOne);
            this.payloadUnmoney.incomeSourceProofTwo = this.unMoneyFormat(this.payload.incomeSourceProofTwo);
            this.payloadUnmoney.outcomeOne = this.unMoneyFormat(this.payload.outcomeOne);
            this.payloadUnmoney.outcomeTwo = this.unMoneyFormat(this.payload.outcomeTwo);
            this.payloadUnmoney.outcomeThree = this.unMoneyFormat(this.payload.outcomeThree);
            this.payloadUnmoney.outcomeFour = this.unMoneyFormat(this.payload.outcomeFour);
            this.payloadUnmoney.incomeSourceId = this.incomeSources.id;

            return (
                this.payloadUnmoney.incomeSourceProofOne > 0 &&
                this.payloadUnmoney.incomeSourceProofTwo >= 0 &&
                this.payloadUnmoney.outcomeOne > 0 &&
                this.payloadUnmoney.outcomeTwo > 0 &&
                this.payloadUnmoney.outcomeThree > 0 &&
                this.payloadUnmoney.outcomeFour > 0
            )
        }
    }
}
</script>