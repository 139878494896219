<template>
    <div id="income-source-proofs-document" class="ml-1 mr-1">
        <div class="vx-row mb-base">
            <div class="vx-col w-full">
                <h2>Comprobantes de ingresos</h2>
                <p>Ingresa los comprobantes de ingresos no mayor a 3 meses, legibles, sin cortes.</p>
                <vs-divider></vs-divider>
                <!-- {{ incomeSourceProofs }} -->
            </div>
            <div class="vx-col sm:w-full md:w-11/12 mb-4 mt-4">
                <!-- <h4 class="mb-4">Selección del documento</h4> -->
                <vs-alert class="mb-base" color="warning" v-if="firstIncomeSource == null">
                    Algo salió mal, no pudimos encontrar fuentes de ingresos para esta solicitud. Por favor comunicate con nuestro servicio de ayuda a tráves de nuestro chat en línea.
                </vs-alert>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-5/12">
                        <div class="document-detail mb-base">
                            <p class="bold black text-lg mb-4">Selecciona el tipo de comprobante de ingresos</p>
                            <p class="black w-full">
                                Deberás subir por lo menos 3 comprobantes de ingresos del mismo tipo para continuar con tu solicitud;
                                además, si así lo deseas puedes subir más comprobantes de ingresos del tipo de comprobante disponible
                                para mejorar tus posibilidades de aprobación.
                            </p>
                            <div class="vx-row w-full mt-6 mb-base">
                                <div class="vx-col w-1/2 mb-8 mt-2" v-for="(item, index) in documentTypeOptions" :key="index">
                                    <rg-radio class="w-full" groupName="needsInitialPayment" :label="item.label" :value="item.value" v-model="documentTypeSelected" />
                                </div>
                                <!-- <div class="vx-col w-1/2">
                                    <vs-button class="w-full p-2" size="sm" color="primary" type="border" @click="setDocumentType(index, 1)">Recibo de nómina.</vs-button>
                                </div>
                                <div class="vx-col w-1/2">
                                    <vs-button class="w-full p-2" size="sm" color="primary" type="border" @click="setDocumentType(index, 2)">Estado de cuenta bancario.</vs-button>
                                </div> -->
                            </div>
                        </div>
                        <div class="document-detail">
                            <p class="bold black text-lg mb-4">Antes de subir el documento verifica que el comprobante:</p>
                            <ul class="check-ul">
                                <li class="black">Tenga <span class="bold">máximo 3 meses</span> de antigüedad.</li>
                                <li class="black">Muestre <span class="bold">detalle de tus ingresos</span>. Debe ser legible.</li>
                                <li class="black">Esté completo, sin cortes.</li>
                                <li class="black">Cumpla con formato PDF, JPG, GIF o PNG.</li>
                                <li class="black">El archivo pese máximo 10 MB.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="vx-col sm:w-full md:w-7/12 pl-16">
                        <div v-if="documentTypeSelected != null" class="vx-row">
                            <div class="vx-col w-full">
                                <p class="bold black text-lg mb-4">{{ documentTypeSelected == 1 ? 'Tus recibos de nómina:' : 'Tus estados de cuenta bancario:' }}</p>
                            </div>
                            <div class="vx-col sm:w-full md:w-1/3 mr-0 center" v-for="(proof, index) in incomeSourceProofs">
                                <div v-if="!hasAlreadyDocument(index) || isUpdatingByBtn(index)">
                                    <vx-upload
                                        class="upload-container"
                                        :text="getFileText"
                                        ref="vsupload"
                                        :automatic="true"
                                        :action="uploadAction(index)"
                                        :data="getDataForm(proof, documentTypeSelected)"
                                        :alreadyLoaded="isUpdating(index)"
                                        :fileIsVerified="isVerified(index)"
                                        :fileIsRejected="isRejected(index)"
                                        :loadedFileName="documentName(index)"
                                        fileName="file"
                                        :acceptFileTypes="['pdf','jpeg','jpg','png']"
                                        :headers="headers"
                                        @on-success="successUpload"
                                        @change="onChangeEvent(index)"
                                        @on-invalid-extension="onInvalidExtensionEvent"
                                    />
                                    <small v-if="!isRejected(index)" class="center">{{ getProofName(index) }} comprobante</small>
                                    <p v-if="isRejected(index)" class="bold mb-2" :class="getChipColor(index)">{{ getSelectedDocument(index).is_verified.toUpperCase() }}</p>
                                    <small v-if="isRejected(index)" class="black"><span class="bold">Motivos de rechazo:</span> {{ getSelectedDocument(index).reject_reason }}</small>
                                </div>
                                <div v-else class="vx-col w-full mt-4 center address-card">
                                    <feather-icon icon="FileTextIcon" svgClasses="w-10 h-10 text-primary" />
                                    <p class="black mb-1 mt-2">{{ getProofName(index) }} <br/> comprobante cargado</p>
                                    <p class="black mb-2">{{ dateTimeFormat(getSelectedDocument(index).created_at) }}</p>
                                    <p class="bold mb-2" :class="getChipColor(index)">{{ getSelectedDocument(index).is_verified.toUpperCase() }}</p>
                                    <small v-if="!isVerified(index)" class="bold mb-2 clickable-img" @click.stop="updateDocument(index)"><u>Actualizar</u></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 md:w-1/12">
                <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
            </div>
            <div class="vx-col sm:w-1/2 md:w-2/12">
                <!-- <vs-button class="w-full" size="sm" color="black">Continuar</vs-button> -->
            </div>
        </div>
    </div>
</template>

<script>
import VxUpload from '@upload/VxUpload';
import dateHelper from '@mixins/dateHelper';

const PF_INCOME_SOURCE_PROOF_DOCUMENT_PREFIX = "pf_applicant_income_sources";
const PF_INCOME_SOURCE_PROOF_DOCUMENT_ID = 133;

const documentTypeOptions = [
    { label: "Recibo de nómina", value: 1, desc: null },
    { label: "Estado de cuenta bancario", value: 2, desc: null },
];

export default {
    name: "IncomeSourceProofsDocument",
    props: {
        incomeSources: Array
    },
    components: {
        VxUpload
    },
    mixins: [dateHelper],
    data: () => ({
        onError: false,
        onSuccess: false,
        hasFile: false,
        /* mensajes */
        genericError: "Ha ocurrido un error con la carga.",
        invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
        updateError: "Ha ocurrido un error al intentar actualizar",
        remainMssg: "El archivo cargado previamente seguirá activo.",
        apiErrors: "",
        currentDocument: null,
        document_type: [],
        currentIndex: null,
        documentTypeSelected: 1,
        documentTypeOptions: documentTypeOptions
    }),
    computed: {
        firstIncomeSource() {
            return this.incomeSources[0];
        },
        incomeSourceProofs() {
            if(this.firstIncomeSource != null) {
                return this.firstIncomeSource.income_sources_proofs.filter(proof => proof.income_source_document_id == this.documentTypeSelected);
            } else {
                return [];
            }
        },
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        currentAccessToken(){
            return this.AccessToken;
        },
        headers(){
            // "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
            //     "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
            return {
                "Access-Control-Allow-Origin": false,
                "Access-Control-Allow-Credentials": false,
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": "Bearer " + this.currentAccessToken,
            };
        },
        showAlerts(){
            return (this.onSuccess || this.onError);
        },
        getFileText() {
            return "Selecciona o arrastra aquí tu documento";
            // return "Seleccione o arrastra tu " + (this.documentTypeSelected == 1 ? "recibo de nómina" : "estado de cuenta bancario");
        }
    },
    methods: {
        async goBack() {
            await this.$emit('on-back');
        },
        getProofName(index) {
            const proofName = {
                0: "Primer",
                1: "Segundo",
                2: "Tercer",
                3: "Primer",
                4: "Segundo",
                5: "Tercer",
            };

            return proofName[index]||"";
        },
        onChangeEvent(index) {
            console.log(index);
            this.currentIndex = index;
        },
        onInvalidExtensionEvent(){
            this.onError = true;
            this.invalidExtension = true;
        },
        successUpload(e) {
            this.$vs.notify({
                title: '¡Documento guardado!',
                text: 'Tu comprobante de domicilio ha sido guardado con éxito.',
                color: 'success',
                position: 'top-right'
            });
            const documentData = JSON.parse(e);
            this.incomeSourceProofs[this.currentIndex].document_file_id = documentData.id;
            this.incomeSourceProofs[this.currentIndex].document_file = documentData;
            this.incomeSourceProofs[this.currentIndex].document_file.is_verified = "sin verificar";

            let currentDocumentType = this.document_type[this.currentIndex];
            this.incomeSourceProofs[this.currentIndex].document_type = currentDocumentType == 1 ? "Recibo de nómina" : "Estado de cuenta bancario";
            this.$forceUpdate();
        },
        getDataForm(proof, documentType) {
            return {
                file_type: PF_INCOME_SOURCE_PROOF_DOCUMENT_PREFIX,
                assocData: proof.id,
                documentType: documentType
            };
        },
        setDocumentType(index, type) {
            this.documentTypeSelected = type;
            // this.document_type[index] = type;
            this.$forceUpdate();
        },
        uploadAction(index) {
            let url = this.apiStorageBasePrefix;
            if(this.incomeSourceProofs[index] != null && this.incomeSourceProofs[index].document_file_id != null) {
                return `${url}upload/user/${this.UserId}/update/${this.getSelectedDocument(index).id}`;
            } else {
                return `${url}upload/user/${this.UserId}`;
            }
        },
        showDocumentUploader(index) {
            return this.document_type[index] != null;
        },
        getSelectedDocument(index) {
            if(this.incomeSourceProofs[index].document_file != null) {
                return this.incomeSourceProofs[index].document_file;
            }
            return {};
        },
        getDocumentType(index) {
            return this.incomeSourceProofs[index].document_type;
        },
        isUpdating(index) {
            return this.incomeSourceProofs[index] != null && this.incomeSourceProofs[index].document_file_id != null && !this.isUpdatingByBtn(index)
        },
        hasAlreadyDocument(index) {
            return this.incomeSourceProofs[index] != null && this.incomeSourceProofs[index].document_file_id != null && this.incomeSourceProofs[index].document_file?.is_verified != "rechazado";
        },
        isVerified(index){
            return (this.incomeSourceProofs[index].document_file?.is_verified == "verificado");
        },
        isUpdatingByBtn(index){
            return (this.incomeSourceProofs[index].document_file?.is_verified == "updating");
        },
        isRejected(index){
            if(this.incomeSourceProofs[index].document_file != null) {
                return this.incomeSourceProofs[index].document_file.is_verified == "rechazado";
            }
            return false;
        },
        documentName(index) {
            return this.incomeSourceProofs[index].document_file?.storage_file;
        },
        getChipColor(index) {
            if(this.isVerified(index)) {
                return "text-success";
            } else if(this.isRejected(index)) {
                return "text-danger";
            } else {
                return "text-warning";
            }
        },
        updateDocument(index) {
            // console.log(this.incomeSourceProofs[index]);
            this.incomeSourceProofs[index].document_file.is_verified = "updating";
        }
    }
}
</script>

<style>
.divider-mb {
    margin-bottom: 1rem;
}

</style>